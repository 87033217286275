import { DeviceType, EventType } from '@wix/platform-editor-sdk';
import type {
  EditorReadyFn,
  GetAppManifestFn,
  PagesBuilder,
} from '@wix/yoshi-flow-editor';
import { isValidURL } from '@wix/custom-element-sdk';
import { addMyBusinessPanelConfigurationToHotels } from './app-specific-logic/hotels/hotels-my-business-panel-config';
import { HOTELS_APP_IDS } from './app-specific-logic/hotels/hotels.constants';
import { configureWidgetManifest } from './components/webComponent/manifest';
import {
  customElementComponentType,
  DASHBOARD_ACTION_ID,
  SETTINGS_ACTION_ID,
  TOKEN,
} from './consts';
import { firstInstallFlow } from './lib/installation-flows/first-install-flow';
import { installFromMetaSiteData } from './lib/installation-flows/meta-site-data-install';
import { updateComponentsData } from './lib/lifecycle-flows/update-component';
import { openDashboard } from './lib/panel-flows/dashboard';
import {
  openSettingsPanel,
  reportBiEventGfppSettingsClicked,
} from './lib/panel-flows/settings';
import { initGlobals } from './utils/globals.utils';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  editorOptions,
  flowApi,
) => {
  const { firstInstall, origin } = editorOptions;
  const { customElementSDK, webComponents, instanceId, metaSiteData } =
    await initGlobals({
      flowApi,
      appDefinitionId,
      sdk: editorSDK,
      origin,
    });

  if (metaSiteData) {
    await customElementSDK.deleteMetaSiteCacheData();

    installFromMetaSiteData();
  }

  if (!metaSiteData && firstInstall) {
    await firstInstallFlow();
  }

  const customElementComps = await editorSDK.document.components.findAllByType(
    TOKEN,
    {
      componentType: customElementComponentType,
    },
  );

  // update changes of custom element components (scriptTag, url, initialAttributes)
  await updateComponentsData({
    components: customElementComps,
  });

  editorSDK.addEventListener(EventType.widgetGfppClicked, async (event) => {
    const { detail } = event;

    if (detail.id === DASHBOARD_ACTION_ID) {
      openDashboard(detail.componentRef);
    }

    if (detail.id === SETTINGS_ACTION_ID) {
      openSettingsPanel(detail.componentRef);
      reportBiEventGfppSettingsClicked(flowApi, event);
    }
  });

  editorSDK.addEventListener(EventType.switchedToMobileView, async () => {
    const componentsByType = await editorSDK.document.components.findAllByType(
      TOKEN,
      {
        componentType: customElementComponentType,
      },
    );

    await updateComponentsData({
      components: componentsByType,
      deviceType: DeviceType.Mobile,
    });
  });

  editorSDK.addEventListener(EventType.switchedToDesktopView, async () => {
    const componentsByType = await editorSDK.document.components.findAllByType(
      TOKEN,
      {
        componentType: customElementComponentType,
      },
    );

    await updateComponentsData({
      components: componentsByType,
      deviceType: DeviceType.Desktop,
    });
  });

  editorSDK.addEventListener(EventType.siteWasPublished, async () => {
    for (const component of webComponents) {
      const dataEndpointURL = component.data?.gfppSettings?.fetchInitialData;

      if (isValidURL(dataEndpointURL)) {
        await customElementSDK.sendDataToThirdParty({
          endpoint: dataEndpointURL,
          data: {
            componentId: component.componentId,
            instanceId,
            status: 'published',
          },
        });
      }
    }
  });

  editorSDK.addEventListener(EventType.themeChanged, async (e) => {
    // @ts-expect-error
    if (e?.detail?.changeType === 'STYLE') {
      return;
    }

    const theme = await customElementSDK.getTheme();

    for (const component of webComponents) {
      const dataEndpointURL = component.data?.gfppSettings?.fetchInitialData;

      if (isValidURL(dataEndpointURL)) {
        await customElementSDK.sendDataToThirdParty({
          endpoint: dataEndpointURL,
          data: {
            data: {}, // TODO: check if can be removed
            instanceId,
            theme,
          },
        });
      }
    }

    const componentsByType = await editorSDK.document.components.findAllByType(
      TOKEN,
      {
        componentType: customElementComponentType,
      },
    );

    await updateComponentsData({
      components: componentsByType,
    });
  });
};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  { initialAppData },
  flowApi,
) => {
  const { appDefinitionId } = initialAppData;
  const { isClassicEditor } = flowApi.environment;

  await configureWidgetManifest(appManifestBuilder);

  if (HOTELS_APP_IDS.includes(appDefinitionId)) {
    addMyBusinessPanelConfigurationToHotels({
      appManifestBuilder,
      initialAppData,
    });
  }

  const pageActions: Parameters<PagesBuilder['addAction']>[1][] = [
    {
      type: 'page_delete',
      title: 'Delete',
      onClick: async () => {
        await editorSDK.application.uninstall(TOKEN, {
          openConfirmation: true,
        });
      },
    },
  ];

  if (isClassicEditor) {
    pageActions.unshift({
      type: 'page_rename',
    });
  }

  // configure page options
  return appManifestBuilder
    .configurePages((pagesBuilder) => {
      pagesBuilder
        .addSettingsTab(
          {
            title: 'Page Info',
            helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
            type: 'page_info',
          },
          {
            title: 'Layout',
            type: 'layout',
          },
          {
            title: 'Permissions',
            type: 'permissions',
          },
          {
            title: 'SEO',
            helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
            type: 'seo',
          },
        )
        .addAction(...pageActions);
    })
    .build();
};
